























































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import PostersModule from "@/store/vuex-modules/posters";
import { UpdatePosterPayload } from "@/types/interfaces";

const posterStore = getModule(PostersModule);

@Component({
    components: {
        PageTitleHeader,
        Spinners
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class EditPoster extends Vue {
    @Prop(String)
    id!: string;

    isLoading = true;
    updatePayload: UpdatePosterPayload = {
        attendeeIds: [],
        categories: [],
        description: "",
        posterId: "",
        title: "",
        video: {
            url: ""
        }
    };
    resetPayload: UpdatePosterPayload = {
        attendeeIds: [],
        categories: [],
        description: "",
        posterId: "",
        title: "",
        video: {
            url: ""
        }
    };
    isSubmitting = false;
    submitSuccess = false;
    errorMessage = "";
    submitError = false;

    addToCategory = "";

    get posterCategories() {
        return this.$store.getters.getPageOptions("posters").categories
            ? this.$store.getters.getPageOptions("posters").categories
            : [];
    }

    created() {
        this.initializePoster();
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    initializePoster() {
        const postersPromises = [this.fetchPoster()];

        this.isLoading = true;

        Promise.resolve(postersPromises).then(() => {
            this.isLoading = false;
        });
    }

    fetchPoster() {
        return new Promise<void>((resolve, reject) => {
            posterStore
                .getPoster(this.id)
                .then((response: any) => {
                    // this.poster = response
                    this.updatePayload.title = response.title;
                    this.updatePayload.description = response.description;
                    this.updatePayload.attendeeIds = [...response.attendeeIds];
                    this.updatePayload.categories = [...response.categories];
                    this.updatePayload.posterId = this.id;

                    if (this.updatePayload.video && response.video) {
                        const video = this.updatePayload.video;
                        const resVideo = response.video;
                        video.url = resVideo.url;
                    }

                    return resolve();
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    resetForm() {
        this.updatePayload = JSON.parse(JSON.stringify(this.resetPayload));
    }

    setErrorMessage(message: string | undefined) {
        if (message) {
            this.errorMessage = message;
        }
    }

    submitForm() {
        const payload = JSON.parse(JSON.stringify(this.updatePayload));
        this.isSubmitting = true;

        posterStore
            .editPoster(payload)
            .then(() => {
                this.submitSuccess = true;
            })
            .catch((error) => {
                const data = error?.response?.data;

                this.setErrorMessage(data);
                this.submitError = true;
            })
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    resetSubmitFlags() {
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.submitError = false;
    }

    handleSubmitSuccess() {
        this.resetForm();
        this.$router.push(`/posters/poster-details/${this.id}`);
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
