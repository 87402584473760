import axios from "axios";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { PosterObject, RelatedPosterObject } from "@/types/interfaces";
import Store from "../index";
import {
    PosterObjectOptions,
    UpdatePosterPayload
} from "./../../types/interfaces";

// This module is only for functionality related to the following endpoint:
const endpoint = "posters";

@Module({
    dynamic: true,
    store: Store,
    name: "PostersModule",
    namespaced: true
})
export default class Posters extends VuexModule {
    posters: Array<PosterObject> = [];
    relatedPosters: Array<RelatedPosterObject> = [];
    relatedPostersLoaded = false;
    postersLoaded = false;

    posterImage: File | string = "";
    posterFileName = "";
    posterImageUrl = "";

    @Mutation
    public setPosters(data: Array<PosterObject>) {
        this.posters = data;
        this.postersLoaded = true;
    }

    @Mutation
    public setRelatedPosters(data: Array<RelatedPosterObject>) {
        this.relatedPosters = data;
        this.relatedPostersLoaded = true;
    }

    @Mutation
    public setPosterImage(data: File | string) {
        this.posterImage = data;
    }
    @Mutation
    public setPosterImageFileName(data: string) {
        this.posterFileName = data;
    }
    @Mutation
    public setPosterImageUrl(data: string) {
        this.posterImageUrl = data;
    }

    @Action({ commit: "setPosters" })
    getPosters() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    getPoster(posterId: string) {
        const Id = posterId;
        const token = this.context.rootGetters.idToken;
        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${Id}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    const returnValue = response.data;
                    return resolve(returnValue);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setPostersByCategory" })
    getPostersByCategory(catParam: string) {
        // console.log(catParam);
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/postersByCategories/${catParam}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setPosters", rawError: true })
    async editPoster(payload: UpdatePosterPayload) {
        const { posterId, ...rest } = payload;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${posterId}`, rest, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setRelatedPosters" })
    async getRelatedPosters(posterIds: Array<string>) {
        const returnArray: Array<unknown> = [];

        posterIds.forEach(async (posterId) => {
            const res = await this.getPoster(posterId);
            returnArray.push(res);
        });

        return returnArray;
    }

    @Action({ commit: "setPosterImageUrl", rawError: true })
    async getPosterImageUrl(options: PosterObjectOptions) {
        const posterId = options.id;
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(
                `/${endpoint}/${posterId}/image`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
            return image.data;
        } catch (error) {
            throw new Error("Could not get Logo url.");
        }
    }

    @Action({ rawError: true })
    async uploadPosterImage(options: PosterObjectOptions) {
        const posterId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = { data: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `/${endpoint}/${posterId}/image-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Poster Image put url!");
        }

        try {
            await axios.request({
                url: putUrlResponse.data,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }
}
